/* ====|  Variables  |==== */

:root {
  --primary: #d00055;
  --text: #494949;
  --light-gray-background: #f3f3f3;
  --navbar-height: 35px;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

html,
body {
  padding: 0;
  text-align: center;
  scroll-behavior: smooth;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overscroll-behavior-y: none;
}

section {
  min-height: 100%;
}

::selection {
  background: #d0005748;
  /* color: white; */
}

::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
section {
  /* font-family: "DM Sans", sans-serif; */
  /* font-family: "Italiana", serif; */
  /* font-family: "Prata", serif; */
  /* font-family: "Poppins", sans-serif; */
  font-family: "Lucida Sans Typewriter Regular";
}

h3,
a {
  color: var(--primary);
  font-weight: 400;
}
h1 {
  color: white;
  font-weight: 400;
}
h2 {
  font-size: 32px;
  color: var(--text);
  font-weight: 400;
}
h3 {
  font-size: 40px;
  padding-top: 8%;
  margin-bottom: 5px;
}
h4,
p {
  color: var(--text);
  font-weight: 400;
}

h4 {
  font-size: 24px;
}

p,
li {
  font-size: 16px;
}

i,
#logo,
li,
img,
.button {
  transition: all 400ms;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -o-transition: all 400ms;
}

/* Animations ***************************/

@keyframes grow-down {
  0% {
    transform: scale(1.1);
    filter: blur(8px);
  }
  100% {
    transform: scale(1);
    filter: blur(0px);
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(-110%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-110%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slide-up-rotated {
  0% {
    transform: translateY(145%) rotate(15deg);
  }
  100% {
    transform: translateY(0%) rotate(0deg);
  }
}

@keyframes fade-up-small {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes text-slide-in {
  0% {
    opacity: 0;
    transform: translateX(50px);
    letter-spacing: 3px;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    letter-spacing: unset;
  }
}

@keyframes fade-up-medium {
  0% {
    opacity: 0;
    transform: translateY(120px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-down-small {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-down-small {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}

@keyframes slide-down-out {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(110%);
  }
}

@keyframes fade-loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loading-out {
  0% {
    /* transform: translateY(0); */
    webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255);
  }
  5% {
    webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.7);
    /* transform: translateY(-100%); */
  }
  99% {
    webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    background-color: rgba(255, 255, 255, 0);
    transform: translateY(0);
    /* transform: translateY(-100%); */
  }
  100% {
    webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    background-color: rgba(255, 255, 255, 0);
    /* display: none; */
    transform: translateY(-100%);
  }
  /* 100% {
    display: none;
  } */
}

/* header ***************************/
.navbar {
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1;
}

.navbar-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 20px;
}

.navbar-logo-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* .menu-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  visibility: hidden;
  margin: 0;
} */
.menu-links {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin: 20px 23.75px;
  gap: 12px;
  visibility: hidden;
}

.menu-links.menu-animations {
  visibility: visible;
}

.menu-links.menu-animations li {
  animation: 0.2s grow-down 0s ease-out both;
  /* animation: 0.2s fade-down 0s ease-out both; */
}

.navbar a {
  color: var(--text);
  transition: all ease-out 0.15s;
}
.navbar a:hover {
  color: var(--primary);
}

/* 

.menu-links a:before {
  content: "";
  background: var(--primary);
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all ease-out 0.25s;
}

.menu-links a:hover {
  background-position: 0;
}

.menu-links a:hover::before {
  width: 100%;
} */

.navbar .logo {
  visibility: hidden;
  line-height: 30px;
  font-size: 30px;
}

.navbar svg path {
  fill: var(--text);
  transition: all ease-out 0.15s;
}

.navbar svg:hover path {
  fill: var(--primary);
}

.navbar .logo.logo-animation {
  visibility: visible;
  animation: 0.3s grow-down 0s ease-out both;
  /* animation: 0.3s fade-down 0s ease-out both; */
}

/* .app-logo span {
  font-size: 60px;
  color: white;
} */

.navbar .app-logo {
  visibility: hidden;
  transition: all ease-out 0.2s;
  cursor: pointer;
  margin: 0;
}

.navbar .app-logo.logo-animation {
  visibility: visible;
  animation: 0.3s grow-down 0.05s ease-out both;
  /* animation: 0.3s fade-down 0.05s ease-out both; */
}

.navbar .app-logo span {
  font-size: 75%;
  margin-right: 2px;
}

.navbar .app-logo:hover {
  color: var(--text);
}

.navbar .app-logo:hover span {
  color: var(--primary);
}

.navbar .hamburger {
  color: var(--text);
  transition: all ease-out 0.15s;
  font-size: 30px;
  cursor: pointer;
  animation: 0.3s fade-down 0s ease-out both;
  /* animation: 0.3s fade-down 0s ease-out both; */
  visibility: hidden;
}

.navbar .hamburger.hamburger-animation {
  visibility: visible;
  animation: 0.3s grow-down 0.1s ease-out both;
  /* animation: 0.3s fade-down 0.1s ease-out both; */
}

.navbar .hamburger:hover {
  color: var(--primary);
}

/* Loader ***************************/

.loader {
  width: 100vw;
  height: 100dvh;
  position: fixed;
  z-index: 3;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-out 0.3s;
}
/* 
.loader-content-outer-box {
  overflow: hidden;
} */

/* .loader-content {
  animation: 0.5s slide-down 0.1s ease-out both;
} */

.loader-hide {
  animation: 0.5s loading-out 0.1s ease-out both;
}

/* .loader-hide .loader-content {
  animation: 0.65s slide-down-out 0s ease-out both;
}

.loader .app-logo {
  transform: scale(0.5);
} */

/* Aktuell ***************************/

#Aktuell {
  /* height: 100vh; */
  /* height: 100dvh; */
  height: 100vh;
  padding: 20px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

@supports (height: 100svh) {
  #Aktuell {
    height: 100svh;
  }
}

.overflow-hidden-container {
  overflow: hidden;
}

#Aktuell img {
  position: fixed;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: center center;
  z-index: -1;
  filter: blur(8px);
  transform: scale(1.1);
}

@supports (height: 100svh) {
  #Aktuell img {
    height: 100svh;
  }
}

#Aktuell h4 {
  visibility: hidden;
}

#Aktuell span {
  color: var(--primary);
}

#Aktuell a {
  position: absolute;
  bottom: 20px;
  visibility: hidden;
}

.aktuell-animation#Aktuell img {
  animation: 0.65s grow-down 0s ease-out both;
}

.aktuell-animation#Aktuell h4 {
  visibility: visible;
  color: white;
  animation: 0.35s grow-down 0.35s ease-out both;
  /* animation: 0.35s slide-up-rotated 0.35s ease-out both; */
  /* animation: 0.5s fade-down-small 0.4s ease-out both; */
}

.aktuell-animation#Aktuell a {
  visibility: visible;
  animation: 0.35s grow-down 0.5s ease-out both;
  /* animation: 0.35s fade-up-small 0.5s ease-out both; */
}

#arrow {
  font-size: 30px;
  transition: all ease-out 0.25s;
  color: white;
  rotate: 90deg;
}

#arrow:hover {
  color: var(--primary);
  transform: scale(1.1);
}

/* Projekte ***************************/

#Projektes {
  background-color: var(--light-gray-background);
  margin: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  padding: 60px 20px;
  scroll-margin-top: var(--navbar-height);
}

.column {
  display: flex;
  flex-direction: column;
  gap: inherit;
}

.image-overlay-container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  cursor: pointer;

  opacity: 0;
}

.image-overlay-container .image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: var(--primary);
  transition: all ease-out 0.25s;
}

#Projektes img {
  width: 100%;
  object-fit: cover;
  /* opacity: 0; */
}

/* .projektes-animations img { */
.projektes-animations .image-overlay-container {
  opacity: 1;
  animation: 0.35s fade-up-medium 0s ease-out both;
}

/* .projektes-animations-out img { */
.projektes-animations-out .image-overlay-container {
  opacity: 0;
  animation: 0.35s fade-out-down-small 0s ease-out both;
}

.image-overlay-container:hover .image-overlay {
  opacity: 0.3;
}

/* #Projektes img:hover {
  filter: grayscale(0%);
  box-shadow: var(--shadow);
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
} */

.project-drawer-content {
  width: 100vw;
  position: relative;
}

.project-drawer-content .slider {
  position: relative;
  animation: 0.35s fade-up-medium 0.2s ease-out both;
}

.project-drawer-content .close-button {
  display: inline-flex;
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 4;
  cursor: pointer;
}

.project-drawer-content .close-button {
  font-size: 30px;
  transition: all ease-out 0.25s;
}

.project-drawer-content .close-button path {
  transition: all ease-out 0.25s;
  fill: var(--primary);
}

.project-drawer-content .close-button:hover path {
  fill: var(--text);
}

.project-drawer-content .drawer-arrows {
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  z-index: 10;
}

.project-drawer-content .drawer-arrows path {
  transition: all ease-out 0.25s;
  fill: var(--primary);
}

.project-drawer-content .drawer-arrows:hover path {
  fill: var(--text);
}

.project-drawer-content .arrow-down {
  bottom: 12px;
  transform: rotate(90deg);
}

.project-drawer-content .prev-arrow {
  left: 12px;
  top: calc(50% - 30px);
}

.project-drawer-content .next-arrow {
  right: 12px;
  top: calc(50% - 30px);
}

/* .project-drawer-content .swiper-button-next,
.project-drawer-content .swiper-button-prev {
  color: var(--primary);
}

.project-drawer-content .swiper-button-next::after,
.project-drawer-content .swiper-button-prev::after {
  font-size: 30px;
} */

.project-drawer-content .swiper-pagination {
  bottom: calc(100% - 12px - 22px);
  padding-left: 35px;
}

.project-drawer-content .swiper-pagination-bullet-active {
  background-color: var(--primary);
}

.project-drawer-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 40px;
  gap: 20px;
}

.project-drawer-text-desc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
}

.project-drawer-text h1 {
  animation: 0.35s fade-up-small 0.25s ease-out both;
}

.project-drawer-text p {
  animation: 0.35s fade-up-small 0.35s ease-out both;
}

/* Portrait ***************************/

#Portrait {
  background-color: white;
  scroll-margin-top: var(--navbar-height);
  padding: 60px 20px;
  text-align: left;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  align-items: flex-end;
}

#Portrait img {
  width: 100%;
  /* width: 50%; */
  /* height: auto; */
  transition: all ease-out 0.25s;
}

#Portrait img:hover {
  filter: grayscale(0%);
  /* box-shadow: var(--shadow); */
  /* transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05); */
}

.box {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  margin-top: 40px; */
}

.box .Team {
  display: flex;
  flex-direction: column;
  /* width: 60.5%; */
}

.team-member-name {
  margin: 0px;
  margin-bottom: 12px;
}

.portrait-heading {
  margin: 0px;
}

.portrait-description {
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  gap: 60px;
  margin-top: 12px;
  /* margin: 0; */
}
.portrait-description p,
.Team p {
  margin: 0;
}

.cv {
  padding-top: 12px ;
}

.portrait-animations .portrait-heading,
.portrait-animations .portrait-description p {
  animation: 0.35s fade-up-medium 0s ease-out both;
}

.portrait-animations-out .portrait-heading,
.portrait-animations-out .portrait-description p {
  animation: 0.35s fade-out-down-small 0s ease-out both;
}

.box-animations .Team {
  animation: 0.35s fade-up-small 0s ease-out both;
}

.box-animations-out .Team {
  animation: 0.35s fade-out-down-small 0s ease-out both;
}

.box p {
  margin-top: -15px;
}

/* Kontakt ***************************/

#Kontakt {
  background-color: var(--light-gray-background);
  min-height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

@supports (height: 100svh) {
  #Kontakt {
    min-height: calc(100svh - 52px);
  }
}

#Kontakt a {
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--text);
}

#Kontakt a svg {
  font-size: 16px;
  line-height: 16px;
}

#Kontakt a:hover svg path {
  fill: var(--primary);
}

.kontakt-animations a {
  /* animation: 0.5s slide-up-rotated 0s ease-out both; */
  animation: 0.35s fade-up-small 0s ease-out both;
}

.kontakt-animations-out a {
  /* animation: 0.35s slide-down-out 0s ease-out both; */
  animation: 0.35s fade-out-down-small 0s ease-out both;
}

/* footer ***************************/

footer {
  background-color: white;
  padding-block: 4px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer a {
  color: var(--text);
  /* font-size: 15px; */
  display: inline-block;
  transition: all ease-out 0.25s;
}

footer a:hover {
  color: var(--primary);
}

/* impressum ***************************/

#Impressum {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: none;
  min-height: 100vh;
}

#Impressum:target {
  display: flex;
}

.Impressum-Inhalt {
  text-align: left;
  padding-left: 90px;
  padding-top: 90px;
  padding-bottom: 90px;
  padding-right: 50%;
  animation: 0.35s fade-down-small 0.1s ease-out both;
}

.Impressum-Inhalt h4 {
  margin-bottom: 0px;
}

.Impressum-Inhalt p {
  margin-top: 0px;
}

#Impressum h4 {
  font-weight: 500;
}

/* media queries ***************************/

/* tablets */

@media screen and (max-width: 1024px) {
}

/* mobile */

@media screen and (max-width: 1024px) {
  /* :root {
    --navbar-height: 55px;
  } */

  .navbar-top-bar {
    font-size: 30px;
  }

  .navbar-logo-container {
    gap: 10px;
  }

  .navbar .hamburger {
    font-size: 30px;
  }

  .menu-links {
    align-items: flex-end;
    flex-direction: column;
    margin: 20px 23.75px;
    gap: 12px;
  }

  .navbar .app-logo {
    font-size: 16px;
  }

  #Aktuell h4 {
    font-size: 20px;
  }

  #arrow {
    font-size: 24px;
  }

  #Projektes {
    grid-template-columns: 1fr;
  }

  .project-drawer-content .drawer-arrows,
  .project-drawer-content .close-button {
    font-size: 24px;
  }

  .project-drawer-content .prev-arrow,
  .project-drawer-content .next-arrow {
    display: none;
  }

  /* 
  .project-drawer-content .arrow-down {
    display: none;
  } */

  .project-drawer-content .swiper-pagination {
    padding-left: 24px;
  }

  .project-drawer-text {
    padding-inline: 20px;
  }

  .project-drawer-text-desc {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  /* #Portrait img {
    width: 100%;
    height: auto;
  }

  .box {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .portrait-description {
    grid-template-columns: 1fr;
    gap: 20px;
  } */

  #Portrait {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  #Portrait img {
    width: 100%;
  }

  form input,
  form textarea {
    width: 70%;
  }

  .button {
    width: 74%;
  }

  footer p {
    font-size: 20px;
  }

  .Impressum-Inhalt {
    padding: 50px 20px;
  }
}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Lucida Sans Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Lucida Sans Regular"), url("LSANS.woff") format("woff");
}

@font-face {
  font-family: "Lucida Sans Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Lucida Sans Italic"), url("LSANSI.woff") format("woff");
}

@font-face {
  font-family: "Lucida Sans Demibold Roman";
  font-style: normal;
  font-weight: normal;
  src: local("Lucida Sans Demibold Roman"), url("LSANSD.woff") format("woff");
}

@font-face {
  font-family: "Lucida Sans Demibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Lucida Sans Demibold Italic"), url("LSANSDI.woff") format("woff");
}

@font-face {
  font-family: "Lucida Sans Typewriter Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Lucida Sans Typewriter Regular"), url("LTYPE.woff") format("woff");
}

@font-face {
  font-family: "Lucida Sans Unicode Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Lucida Sans Unicode Regular"), url("l_10646.woff") format("woff");
}

@font-face {
  font-family: "Lucida Sans Typewriter Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Lucida Sans Typewriter Oblique"),
    url("LTYPEO.woff") format("woff");
}

@font-face {
  font-family: "Lucida Sans Typewriter Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Lucida Sans Typewriter Bold"), url("LTYPEB.woff") format("woff");
}

@font-face {
  font-family: "Lucida Sans Typewriter Bold Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Lucida Sans Typewriter Bold Oblique"),
    url("LTYPEBO.woff") format("woff");
}
